import { useNavigate, useLocation } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { VscArrowDown } from "react-icons/vsc";
import { ReactComponent as ArrowLeft } from "icons/icon-arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/icon-arrow-right.svg";
import { ReactComponent as IconRoomFull } from "icons/icon-room-full-small.svg";
import { ReactComponent as IconRoomSingle } from "icons/icon-room-single-small.svg";
import { BASE_URL } from "helpers/constants";
import { localePrice } from "helpers/locales";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";

import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "features/userSlice";
import { useAddReservationMutation } from "app/eventsApi";
import { setIsModal, setLastLocation, setMessage } from "features/appSlice";
import {
  add,
  addReservationId,
  selectReservationId,
} from "features/reservationSlice";

const SuitCard = ({ suit, event, selectedPackage }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const reservationId = useSelector(selectReservationId);
  const user = useSelector(selectUser);
  const [addReservation] = useAddReservationMutation();

  const isFullRoomAvailable =
    suit.reservation_type === 2
      ? false
      : suit.room_available_type?.includes("Номер целиком");
  const isSingleRoomAvailable =
    suit.room_available_type?.includes("Место в номере");
  const roomGender =
    suit.available_gender?.length === 1 &&
    (suit.available_gender[0] === "Мужской" ? "(муж.)" : "(жен.)");

  const roomInfoData = [
    isFullRoomAvailable && {
      icon: <IconRoomFull />,
      text: "Номер целиком",
    },
    isSingleRoomAvailable && {
      icon: <IconRoomSingle />,
      text: `Место в номере${roomGender ? ` ${roomGender}` : ""}`,
    },
  ].filter((el) => el);

  // Swiper bullets
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class=${className}></span>`;
    },
  };

  const pluralize = (num) => {
    if (num === 1) return "о";
    if (num < 5) return "а";
    return "";
  };

  // Redux dispatch props
  const eventData = {
    slug: event.slug,
    header: event.header,
    event_id: event.id,
    suits: [],
  };
  const suitData = {
    id: suit.id,
    capacity: suit.capacity,
    min_capacity: suit.min_capacity,
    reservation_type: suit.reservation_type,
    name: suit.name,
    image: suit.image[0],
    options: {
      package: selectedPackage,
      type: suit.reservation_type === 2 ? "Номер целиком" : "",
      quantity: 0,
      gender: "",
    },
    participants: [],
  };
  const reservationData = user
    ? reservationId
      ? { user_id: user.id, reservation_uuid: reservationId }
      : { user_id: user.id }
    : reservationId && { reservation_uuid: reservationId };
  const packageData = event.packages.map((el) => {
    const { suits, ...rest } = el;
    return rest;
  });

  // Add reservation func
  const handleAddReservation = async () => {
    try {
      const result = await addReservation({
        event_room_id: suit.id,
        event_id: event.id,
        ...reservationData,
      }).unwrap();
      const { reservation_uuid, ...rest } = result;
      dispatch(addReservationId(reservation_uuid));
      dispatch(setLastLocation(location.pathname));
      dispatch(
        add({
          eventData: {
            ...eventData,
            packages: packageData,
          },
          suitData: {
            ...suitData,
            ...rest,
          },
        })
      );
      navigate("/reservation", { state: suitData.id });
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg: "К сожалению в данный момент этот номер недоступен",
        })
      );
    }
  };

  return (
    <article className="card suit-card">
      {suit.image.length > 1 ? (
        <Swiper
          loop
          pagination={pagination}
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: ".swiper-arrows .prev",
            nextEl: ".swiper-arrows .next",
          }}
        >
          {suit.image.map((img, id) => {
            return (
              <SwiperSlide key={id}>
                <img src={`${BASE_URL}${img}`} alt={img} />
              </SwiperSlide>
            );
          })}
          <div className="swiper-arrows">
            <ArrowLeft className="prev" />
            <ArrowRight className="next" />
          </div>
        </Swiper>
      ) : (
        <div className="swiper">
          <div className="swiper-slide">
            <img src={`${BASE_URL}${suit.image[0]}`} alt={suit.image[0]} />
          </div>
        </div>
      )}

      <div className="suit-card-text">
        <div className="suit-card-text-title">
          <h5>
            <span>
              Пакет участника
              {suit.reservation_type !== 2 ? " с типом размещения" : ""}:
            </span>
            <br />
            {suit.name}
          </h5>
        </div>
        {roomInfoData.length > 0 && (
          <div className="suit-card-info">
            {roomInfoData.map((el, id) => (
              <div key={id}>
                {el.icon}
                <span className="info-text">{el.text}</span>
              </div>
            ))}
          </div>
        )}
        {event.services_descr && (
          <div className="suit-card-services">
            <b>Включено:</b>
            <div className="services-list">
              {event.services_descr.map((el, id) => (
                <span key={id}>{el}</span>
              ))}
            </div>
          </div>
        )}
        <ul>
          {suit.rate.map((item, id) => {
            return (
              <li key={id}>
                <div className="rate-text">
                  <span>{item.name}</span>
                  {item.subtext && <small>{item.subtext}</small>}
                </div>
                <div className="rate-price">
                  {item.sale_price && (
                    <span>{localePrice(item.sale_price)}</span>
                  )}
                  <span>{localePrice(item.price)}</span>
                </div>
              </li>
            );
          })}
        </ul>
        {suit.current_price_stage_remains && (
          <div className="remaining">
            Осталось <strong>{suit.current_price_stage_remains}</strong> мест
            {pluralize(suit.current_price_stage_remains)} по этой цене
          </div>
        )}
        {suit.discount && (
          <div className="discount">
            <details className="discount-container">
              <summary>
                <div className="badge">{suit.discount.discount_total}</div>
                <span>Ваша персональная скидка</span>
                <VscArrowDown />
              </summary>
              <ul>
                {suit.discount.discounts.map((el, id) => (
                  <li key={id}>
                    <span>{el.name}</span>
                    <span>{el.rate}</span>
                  </li>
                ))}
              </ul>
            </details>
            <span
              className="text-link"
              data-theme="light"
              onClick={() =>
                dispatch(
                  setIsModal({ modalName: "discount", ...suit.discount })
                )
              }
            >
              Как поехать дешевле?
            </span>
          </div>
        )}
      </div>
      <button className="btn btn-suit" onClick={handleAddReservation}>
        <FiPlus /> Забронировать
      </button>
    </article>
  );
};

export default SuitCard;
