const ReservationHeader = ({ step, isPartner }) => {
  const steps = ["Ваш выбор", "Ваши данные", "Оплата"];

  if (isPartner)
    return (
      <div className="reservation-header" id="reservation">
        <span className="reservation-header-divider" />
      </div>
    );

  return (
    <div className="reservation-header" id="reservation">
      {steps.map((el, id) => {
        return (
          <div
            className={
              step >= id + 1 ? "reservation-step active" : "reservation-step"
            }
            key={id}
          >
            <span
              className={
                +step === id + 1
                  ? "reservation-step-number active"
                  : "reservation-step-number"
              }
            >
              {id + 1}
            </span>
            <span
              className={
                +step === id + 1
                  ? "reservation-step-title active"
                  : "reservation-step-title"
              }
            >
              {el}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ReservationHeader;
