import { useRef } from "react";
import { VscArrowDown } from "react-icons/vsc";
import ReactMarkdown from "react-markdown";
import { useOutletContext } from "react-router-dom";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useBool } from "hooks";
import EventGallery from "./EventGallery";

const EventDescription = () => {
  const { description, refund, isPartner } = useOutletContext();
  const showMore = useBool({ value: true });
  const articleRef = useRef(null);

  // const handleClick = () => {
  //   showMore.value && window.scroll({ top: articleRef.current.offsetTop });
  //   showMore.toggle();
  // };

  const handleSlider = (children) => {
    // const img = children[1]?.props?.children[0]?.split(",");
    const img = children[1]?.props?.children[0]?.props?.children[0]?.split(",");
    if (!img) return "";
    return <EventGallery img={img} />;
  };

  return (
    <article
      className="event-description"
      aria-label="описание выезда"
      ref={articleRef}
    >
      <div
        className="event-description-text"
        data-show={showMore.value || undefined}
      >
        <ReactMarkdown
          components={{
            blockquote: ({ children, ...props }) => handleSlider(children),
          }}
          rehypePlugins={[remarkGfm, rehypeRaw]}
          children={description}
        />
      </div>
      {!isPartner && refund?.length > 0 && (
        <details className="event-refund">
          <summary>
            <VscArrowDown />
            Схема возврата средств при отказе от выезда
          </summary>
          <table>
            <thead>
              <tr>
                {["Срок предупреждения", "Сумма возврата"].map((el, id) => (
                  <th key={id}>{el}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {refund.map((el, id) => (
                <tr key={id}>
                  <td>{el.name}</td>
                  <td>{el.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </details>
      )}
      {/* <button
        className={
          !showMore.value
            ? "btn-inline-icon toggle-content"
            : "btn-inline-icon toggle-content active"
        }
        onClick={handleClick}
      >
        <VscArrowDown />
        {!showMore.value ? "читать дальше" : "скрыть описание"}
      </button> */}
    </article>
  );
};

export default EventDescription;
