import { ReactComponent as ArrowLeft } from "icons/icon-arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/icon-arrow-right.svg";
import { ReactComponent as IconRoomFull } from "icons/icon-room-full-small.svg";
import { ReactComponent as IconRoomSingle } from "icons/icon-room-single-small.svg";
import { BASE_URL } from "helpers/constants";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";

const SuitCardPartner = ({ suit, event }) => {
  const isFullRoomAvailable =
    suit.reservation_type === 2
      ? false
      : suit.room_available_type?.includes("Номер целиком");
  const isSingleRoomAvailable =
    suit.room_available_type?.includes("Место в номере");
  const roomGender =
    suit.available_gender?.length === 1 &&
    (suit.available_gender[0] === "Мужской" ? "(муж.)" : "(жен.)");

  const roomInfoData = [
    isFullRoomAvailable && {
      icon: <IconRoomFull />,
      text: "Номер целиком",
    },
    isSingleRoomAvailable && {
      icon: <IconRoomSingle />,
      text: `Место в номере${roomGender ? ` ${roomGender}` : ""}`,
    },
  ].filter((el) => el);

  // Swiper bullets
  const pagination = {
    clickable: true,
    renderBullet: (_, className) => {
      return `<span class=${className}></span>`;
    },
  };

  return (
    <article className="card suit-card">
      {suit.image.length > 1 ? (
        <Swiper
          loop
          pagination={pagination}
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: ".swiper-arrows .prev",
            nextEl: ".swiper-arrows .next",
          }}
        >
          {suit.image.map((img, id) => {
            return (
              <SwiperSlide key={id}>
                <img src={`${BASE_URL}${img}`} alt={img} />
              </SwiperSlide>
            );
          })}
          <div className="swiper-arrows">
            <ArrowLeft className="prev" />
            <ArrowRight className="next" />
          </div>
        </Swiper>
      ) : (
        <div className="swiper">
          <div className="swiper-slide">
            <img src={`${BASE_URL}${suit.image[0]}`} alt={suit.image[0]} />
          </div>
        </div>
      )}

      <div className="suit-card-text">
        <div className="suit-card-text-title">
          <i>мероприятие партнера</i>
          <h5>
            <span>
              Пакет участника
              {suit.reservation_type !== 2 ? " с типом размещения" : ""}:
            </span>
            <br />
            {suit.name}
          </h5>
        </div>
        {roomInfoData.length > 0 && (
          <div className="suit-card-info">
            {roomInfoData.map((el, id) => (
              <div key={id}>
                {el.icon}
                <span className="info-text">{el.text}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <a
        href={event.partner_link}
        rel="noreferrer"
        target="_blank"
        className="btn btn-suit"
      >
        Забронировать
      </a>
    </article>
  );
};

export default SuitCardPartner;
